import SlideToggle from "react-slide-toggle";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import macbook_img from "../../../../assets/images/products/Laptops/macbook Pro max.jpg";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getCartDetails,
  setCartDetails,
} from "../../../../store/cart/cartDetailsSlice";
import { ToastContainer, toast } from "react-toastify";
import ALink from "../../../common/ALink";
import Qty from "../../../common/Qty";
import AddToCartPopup from "../../../features/modals/add-to-cart-popup";
import { getWishListItems, setWishListItems } from "../../../../store/wishlist/wishlist";
import AddToWishlistPopup from "../../../features/modals/add-to-wishlist-popup";

const ProductDetailOne = (props) => {
  const [attrs, setAttrs] = useState({ sizes: [], colors: [] });
  const [variant, setVariant] = useState(null);
  const [size, setSize] = useState(null);
  const [color, setColor] = useState(null);
  const [qty, setQty] = useState(1);
  const [updatedCartItems, setUpdatedCartItems] = useState([]);
  const [updatedWishListItems, setUpdatedWishListItems] = useState([]);
  const cartData = useSelector(getCartDetails);
  const wishListData = useSelector(getWishListItems);
  const [selectionOptionCombinations, setSelectionOptionCombinations] = useState([]);
  const [optionCombinations, setOptionCombinations] = useState([]);
  const [productData, setProductData] = useState();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedIndices, setSelectedIndices] = useState({});
  const [productImage ,setProductImage]=useState([]);
  const [price ,setPrice]=useState([]);
  const [product ,setProduct]=useState([]);
  const [checkStock,setCheckStock]=useState([]);
   const [stockQty ,setStockQty]=useState([]);


  useEffect(() => {
    setProductData(props.product)
    setProduct(props.product)
  }, [props.product])
  
  useEffect(() => {
    setUpdatedCartItems(cartData?.cartData?.data);
  }, [cartData]);

  useEffect(() => {
    setUpdatedWishListItems(wishListData?.wishListData?.data);
  }, [wishListData]);

  useEffect(() => {
    setOptionCombinations(productData && productData?.options && productData?.options.length > 0 && productData?.options[0]?.options)
  }, [props.product])


  // useEffect(() => {
  //   if (productData?.available_options && productData.available_options.length > 0) {
  //     const initialSelections = productData.available_options
  //       .map((option, index) => {
  //         const firstOption = option?.optionValue?.[0];
  //         console.log('first Options ',firstOption);
  //         if (firstOption) {
  //           return {
  //             itemName: option.option,
  //             name: firstOption,
  //             itemIndex: index,
  //             _id: firstOption?.variant_sf_id,
  //           };
  //         }
  //         return null;
  //       })
  //       .filter(Boolean);
  //        console.log('initial selection' , initialSelections);
  //     setSelectionOptionCombinations(initialSelections);
  //   }
  // }, [productData]);

  useEffect(() => {
    if (product?.options && product.options.length > 0 && product.available_options) {
        const initialSelectedIndices = {};
        product.available_options.forEach((item, index) => {
            if (item?.optionValue?.length > 0) {
                initialSelectedIndices[index] = 0; // Default to first option
            }
        });

        console.log('initialSelectedIndices', initialSelectedIndices);
        setSelectedIndices(initialSelectedIndices);

        // Function to find the first combination with stock quantity greater than zero
        const findValidCombination = (options, combination = [], indices = [], index = 0) => {
            if (index === options.length) {
                const optionsSelected = combination.join(" ");
                console.log('option selected ',optionsSelected);
                //need to change to zero index
                const matchingOption = product.options[0]?.options?.find((value) => {
                    return value.name === optionsSelected;
                });
                console.log('matchingOption',matchingOption);
                console.log('Matching Stock',matchingOption.stockQuantity);
                if (matchingOption && matchingOption?.stockQuantity > 0) {
                    return { matchingOption, indices };
                }
                return null;
            }
            for (let i = 0; i < options[index].optionValue.length; i++) {
                combination[index] = options[index].optionValue[i];
                indices[index] = i;
                const result = findValidCombination(options, combination, indices, index + 1);
                if (result) {
                    return result;
                }
            }
            return null;
        };

        const validCombination = findValidCombination(product.available_options);

        console.log('DefaultSelectedOption', validCombination?.matchingOption);

        if (validCombination) {
            const { matchingOption, indices } = validCombination;
            const selectedOptionDetails = {
                variant_sf_id: matchingOption.variant_sf_id,
                name: matchingOption.name,
                price: matchingOption.price,
                stockQty:matchingOption.stockQuantity
            };
            console.log('matching options image',matchingOption.imageUrls);
            setProductImage(matchingOption.imageUrls)
            console.log('matchingOption.price', matchingOption.price);
            console.log('selectedOptionDetails ',selectedOptionDetails);
            setPrice(matchingOption.price);
            setSelectedOptions(selectedOptionDetails);
            setSelectedIndices(indices);
            // setStockQty(matchingOption.stockQuantity);
        } else {
            setPrice(product.price);
        }
    } else {
        setPrice(product.price);
    }
}, [product]);

const selectOption = (optionIndex, itemIndex, e) => {
  if (e) e.preventDefault();

  const updatedIndices = {
      ...selectedIndices,
      [optionIndex]: itemIndex
  };
  setSelectedIndices(updatedIndices);

  const selectedOptionValues = product.available_options.map((item, index) => {
      const itemIndex = updatedIndices[index];
      return item?.optionValue?.[itemIndex];
  });

  const optionsSelected = selectedOptionValues.join(" ");
  console.log('optionsSelected',optionsSelected);
  //need to change option array of 0 index
  const matchingOption = product.options[0]?.options?.find((value) => {
      return value.name === optionsSelected;
  });
  console.log('matchingOption',matchingOption)
  console.log('matchingOption.stockQuantity',matchingOption.stockQuantity);
  setStockQty(matchingOption.stockQuantity)
      if(matchingOption.stockQuantity===0){
          setCheckStock(false);
      }else{
          setCheckStock(true)
      }
  if (matchingOption) {
      const selectedOptionDetails = {
          variant_sf_id: matchingOption.variant_sf_id,
          name: matchingOption.name,
          price: matchingOption.price,
          stockqty:matchingOption.stockQuantity
      };
      console.log('matching Product Image',matchingOption);
      console.log('matchingOption.price', matchingOption.price);
      console.log('selectedOptionDetails',selectedOptionDetails);
      setProductImage(matchingOption.imageUrls);
      setPrice(matchingOption.price);
      setSelectedOptions(selectedOptionDetails);
  }
};

const isSelected = (optionIndex, itemIndex) => {
  return selectedIndices[optionIndex] === itemIndex;
};



  const findVariation = (itemName, itemIndex) => {
    return (
      Array.isArray(selectionOptionCombinations) &&
      selectionOptionCombinations.some(
        (option) => option.name === itemName && option.itemIndex === itemIndex,
      )
    );
  };

  const dispatch = useDispatch();

  function isInWishlist() {
    // return productData && props.wishlist.findIndex(item => item.slug === product.slug) > -1;
    // return productData ;
  }

  function findMatchingItem(initialArray, newArray) {
    console.log('intial array , new array', initialArray, newArray);
    // Step 1: Create a string from the initial array
    const searchString = initialArray.map(item => item.name).join(',');

    // Step 2: Split the search string into parts
    const searchParts = searchString.split(' ');

    // Step 3: Find the item in the new array where all parts of the search string are included in the name field
    const foundItem = newArray?.find(item => {
      return searchParts.every(part => item.name.includes(part));
    });
    console.log('Found item',foundItem);
    return foundItem;
  }
  function showWishListToastWithImage() {
    const toastContent = () => (
      <div>
        <AddToWishlistPopup props={productData} />
      </div>
    );

    toast(toastContent);
  }

  function onWishlistClick(e) {
    e.preventDefault();
    dispatch(setWishListItems({ data: [productData] }))
    if (updatedWishListItems && updatedWishListItems.length > 0) {
      let productArray = [...updatedWishListItems, productData];
      dispatch(setWishListItems({ data: productArray }));
      showWishListToastWithImage();
    } else {
      let productArray = [productData];
      dispatch(setWishListItems({ data: productArray }));
      showWishListToastWithImage();

    }
    // if (!isInWishlist()) {
    //     let target = e.currentTarget;
    //     target.classList.add("load-more-overlay");
    //     target.classList.add("loading");

    //     setTimeout(() => {
    //         target.classList.remove('load-more-overlay');
    //         target.classList.remove('loading');
    //         props.addToWishList(product);
    //     }, 1000);
    // } else {
    //     router.push('/pages/wishlist');
    // }
  }

  // const onAddCartClick = (productDetails) => {
  //   // productDetails.qty = 1;
  //   if (selectionOptionCombinations && selectionOptionCombinations.length > 0) {
  //     console.log('my array added product ', selectionOptionCombinations);
  //     const selectedItem = findMatchingItem(selectionOptionCombinations, optionCombinations);
  //     console.log('result', selectedItem, optionCombinations); // Output: undefined (since 'Blue,XL' i

  //     const products_variant = {
  //       option_value: selectedItem?.name,
  //       variant_sf_id: selectedItem?.variant_sf_id,
  //       Price: selectedItem?.Price
  //     }

  //     const productWithVariants = {
  //       ...productDetails,
  //       variants: products_variant,
  //     };
  //     showToastWithImage();

  //     if (updatedCartItems && updatedCartItems.length > 0) {
  //       let productArray = [...updatedCartItems, productWithVariants];
  //       dispatch(setCartDetails({ data: productArray }));
  //     } else {
  //       let productArray = [productWithVariants];
  //       dispatch(setCartDetails({ data: productArray }));
  //     }
  //   } else {
  //     let productArray = [...updatedCartItems, productDetails];
  //     dispatch(setCartDetails({ data: productArray }));
  //     showToastWithImage();
  //   }
  // };


  function onAddCartClick(productDetails, quantity) {
    // Create the product variant array 
    console.log('Selected Options on Add To cart ',selectedOptions);
    console.log('Selected Options on Add To cart ',Object.keys(selectedOptions).length);


    const product_varient = Object.keys(selectedOptions).length===0?{
        option_value: "",
        option_price: productDetails.price,
        variant_sf_id: productDetails.sf_id,
        stockQty:productDetails.stockQuantity
}
:{
            option_value: selectedOptions.name,
            option_price: selectedOptions.price,
            variant_sf_id: selectedOptions.variant_sf_id,
            stockQty:selectedOptions.stockQty
    };

    // Create the product object including quantity and variants

       //we are going to add price from useState which we are using dynamically
    const product = { ...productDetails, qty: quantity, variants: product_varient };

    showToastWithImage(product);

    // Initialize updatedCartItemsCopy
    let updatedCartItemsCopy;

    if (!updatedCartItems || updatedCartItems.length === 0) {
        updatedCartItemsCopy = [product];
    } else {
        let productExists = false;

        updatedCartItemsCopy = updatedCartItems.map((cartItem) => {
            if (cartItem.sf_id === product.sf_id) {
                let variantMatch = true;
                console.log('updatedCartItems',updatedCartItems);

                    if (cartItem?.variants?.variant_sf_id !== product_varient.variant_sf_id) {
                        variantMatch = false;
                    }

                if (variantMatch) {
                    productExists = true;
                    return { ...cartItem, qty: cartItem.qty + quantity }; // Update quantity
                }
            }
            return cartItem;
        });

        // If the product is not found or variant does not match, add it to the array
        if (!productExists) {
            updatedCartItemsCopy.push(product);
        }
    }

    console.log('updatedCartItemsCopy',updatedCartItemsCopy)
    // Dispatch the updated cart details
    dispatch(setCartDetails({ data: updatedCartItemsCopy }));
}

  function detectColor(color) {
    // console.log('color for the vars' , color);
    if (
      color.includes("Color" || "COLOR" || "Shade" || "SHADE") ||
      color === "Shade" ||
      color === "shade" ||
      color === "SHADE"
    ) {
      // console.log('yes true');
      return true;
    }
  }
  function showToastWithImage() {
    const toastContent = () => (
      <div>
        <AddToCartPopup props={productData} />
      </div>
    );

    toast(toastContent);
  }

  function changeQty(value) {
    setQty(value);
  }

  // const selectColor = (itemName, name, e, itemIndex, _id) => {
  //   setSelectionOptionCombinations((prevArray) => {
  //     const existingItem = prevArray.find(
  //       (item) => item.itemIndex === itemIndex,
  //     );
  //     if (existingItem) {
  //       // Replace the existing object
  //       return prevArray.map((item) =>
  //         item.itemIndex === itemIndex
  //           ? { ...item, itemName, name, itemIndex, _id }
  //           : item,
  //       );
  //     } else {
  //       // Add a new object
  //       return [...prevArray, { itemName, name, itemIndex: itemIndex, _id }];
  //     }
  //   });
  //   e.preventDefault();
  //   setSize(selectionOptionCombinations);
  // };
  // function handleVariationHover(itemName, name, e, itemIndex, _id) {
  //   // console.log('itemName, name, e, itemIndex, _id' , itemName, name, e, itemIndex, _id);
  // }

  // console.log('product variations added to the cart' , selectionOptionCombinations);
  // const selectVariation = (itemName, name, e, itemIndex, _id) => {
  //   setSelectionOptionCombinations((prevArray) => {
  //     const existingItem = prevArray.find(
  //       (item) => item.itemIndex === itemIndex,
  //     );
  //     if (existingItem) {
  //       // Replace the existing object
  //       return prevArray.map((item) =>
  //         item.itemIndex === itemIndex
  //           ? { ...item, itemName, name, itemIndex, _id }
  //           : item,
  //       );
  //     } else {
  //       // Add a new object
  //       return [...prevArray, { itemName, name, itemIndex: itemIndex, _id }];
  //     }
  //   });
  //   e.preventDefault();
  //   setSize(selectionOptionCombinations);
  //   console.log('Selected Option',selectionOptionCombinations);
  // };

  function initState() {
    setSize(null);
    setColor(null);
    setQty(1);
  }

  function clearVariation(e) {
    e.preventDefault();
    initState();
  }

  // function findVariation(itemName) {
  //     console.log("item");
  //     if (selectionOptionCombinations.some(obj => obj.name === itemName)) {

  //         return true;
  //     } else {
  //         return false;
  //     }

  // }
  // function checkItem(itemName){
  //     console.log('item name inside check item' ,itemName);
  //     if (preSeletedVarition.some(obj => obj.option_value === itemName)) {
  //         console.log('preselected variaitons ' , preSeletedVarition);
  //         return true;
  //     } else {
  //         return false;
  //     }
  // }

  function isDisabled(type, name, index) {
    if (type === "color" && size) {
      return !productData?.option?.find(
        (variant) => variant.name === size && variant.name === name,
      );
    } else if (type === "size" && color) {
      return !productData?.option?.find(
        (variant) => variant.name === color && variant.name === name,
      );
    }
    return false;
  }

  return (
    <>
      {/* <Helmet>
        <title>Product - {productData?.name}</title>
        <meta
          name={productData?.description}
          content="Explore our wide range of products tailored to suit your needs."
        />
        <meta
          name="keywords"
          content="electronics, apparel, gadgets, books, online shopping"
        />
      </Helmet> */}

      {
        <div className={`product-single-details col-lg-7 col-md-6`}>
          <h2 className="product-title w-100 ls-0">{productData?.name}</h2>
          {/* {
                        isNav ?
                            <ProductNav prev={ prev } next={ next } />
                            : ""
                    } */}

          <div className="ratings-container">
            <div className="product-ratings">
              <span
                className="ratings"
                style={{ width: `${20 * productData?.ratings}%` }}
              ></span>
              {/* <span className="tooltiptext tooltip-top">{ productObj.avgRatings.toFixed(0) }</span> */}
            </div>
          </div>

          <div className="price-box">
            {
              <span className="product-price">
                &#x20B9;{price}
              </span>
            }
          </div>

          <div className="product-desc" style={{ width: "700px" }}>
            {/* <p>{productData?.description}</p> */}

            <p
              dangerouslySetInnerHTML={{ __html: productData?.description }}
            ></p>
          </div>

          <ul className="single-info-list">
            <li>
              SKU: <strong>{productData?.product_sku}</strong>
            </li>

            {/* <li>
                                    Stock availability: <strong>2,458</strong>
                                </li> */}

            {/* <li>
                            CATEGORY: { product.categories.map( ( item, index ) =>
                            (
                                <React.Fragment key={ `single-cat-${ index }` }>
                                    <strong>
                                        <ALink href={ { pathname: '/shop', query: { category: item.slug } } } className="category">{ item.name }</ALink>
                                    </strong>
                                    { index < product.categories.length - 1 ? ', ' : '' }
                                </React.Fragment>
                            ) )
                            }
                        </li> */}

            {/* {
                            !product.tags == null && product.tags.length > 0 ?
                                <li>
                                    TAGs: { product.tags.map( ( item, index ) =>
                                    (
                                        <React.Fragment key={ `single-cat-${ index }` }>
                                            <strong>
                                                <ALink href={ { pathname: '/shop', query: { tag: item.slug } } } className="category">{ item.name }</ALink>
                                            </strong>
                                            { index < product.tags.length - 1 ? ', ' : '' }
                                        </React.Fragment>
                                    ) )
                                    }
                                </li>
                                : ''
                        } */}
          </ul>

          {productData?.options?.length > 0 ? (
            <div className="product-filters-container">
              {/* {
                                        productObj?.option[1]?.name === 'Mobile Color' ?
                                            <div className="product-single-filter d-flex align-items-center"><label>Color:</label>
                                                <ul className="config-size-list config-color-list config-filter-list">
                                                    {
                                                        productObj?.option[1]?.options?.map((item, index) => (
                                                            // <li key={`filter-color-${index}`} className={`${item.name === color ? 'active' : ''} ${isDisabled('color', item.name) ? 'disabled' : ''}`}>
                                                            <li key={`filter-color-${index}`} className={`${item.name === color ? 'active' : ''} ${isDisabled('color', item.name) ? 'disabled' : ''}`}>
                                                                {
                                                                        <a href="#" className="filter-color border-2"
                                                                            style={{ backgroundColor: item.name }} onClick={(e) => selectColor(item.name, e)}></a>
                                                                }
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                            : ''
                                    } */}

                    {product?.available_options?.map((item, index) => (
                                        detectColor(item.option) ? (
                                            <div key={index} className="product-single-filter d-flex align-items-center">
                                                <label>Color:</label>
                                                <ul className="config-size-list d-inline-block">
                                                    {item?.optionValue?.map((item1, index2) => (
                                                        <li  key={`filter-color-${index}-${index2}`} className={isSelected(index, index2) ? 'active' : ''} >
                                                            <a href="#" className="d-flex align-items-center justify-content-center"
                                                               onClick={(e) => selectOption(index, index2, e)} disabled={true}>
                                                                {item1}
                                                            </a>
                                                            
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : (
                                            <div key={index} className="product-single-filter d-flex align-items-center">
                                                <label>{item.option}</label>
                                                <ul className="config-size-list d-inline-block">
                                                    {item?.optionValue?.map((item1, index1) => (
                                                        <li key={`filter-size-${index}-${index1}`} className={isSelected(index, index1) ? 'active' : ''}>
                                                            <a href="#" className="d-flex align-items-center justify-content-center" 
                                                               onClick={(e) => selectOption(index, index1, e)}>
                                                                {item1}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )
                                    ))
                                }

              {
                <SlideToggle collapsed={true}>
                  {({ onToggle, setCollapsibleElement, toggleState }) => (
                    <>
                      <button
                        className={`d-none variation-toggle ${toggleState.toLowerCase()}`}
                        onClick={onToggle}
                      ></button>
                      <div
                        className="product-single-filter m-0"
                        ref={setCollapsibleElement}
                      >
                        <label></label>
                        <a
                          className="font1 text-uppercase clear-btn"
                          href="#"
                          onClick={clearVariation}
                        >
                          Clear
                        </a>
                      </div>
                    </>
                  )}
                </SlideToggle>
              }
            </div>
          ) : (
            ""
          )}

          <div className="product-action">
            {/* {
                                productObj?.variants?.length ?
                                    <SlideToggle collapsed={true}>
                                        {({ onToggle, setCollapsibleElement, toggleState }) => (
                                            <>
                                                <button className={`d-none price-toggle ${toggleState.toLowerCase()}`} onClick={onToggle}></button>
                                                <div className="price-box product-filtered-price m-0" ref={setCollapsibleElement}>
                                                    {
                                                        variant && variant.id >= 0 && (variant.price ? variant.sale_price ?
                                                            <>
                                                                <del className="old-price"><span>${variant.price.toFixed(2)}</span></del>
                                                                <span className="product-price">${variant && variant.sale_price.toFixed(2)}</span>
                                                            </>
                                                            : <span className="product-price">${variant && variant.price.toFixed(2)}</span>
                                                            : <span className="product-stock pb-3 d-block">{productObj.is_out_of_stock ? 'Out of Stock' : `${productObj.stock} in stock`}</span>)
                                                    }

                                                </div>
                                            </>
                                        )}
                                    </SlideToggle>
                                    : ''
                            } */}

            {/* <Qty max={ product.stock } value={ qty } onChangeQty={ changeQty } /> */}
            {/* <Qty max={5} value={ 5 } onChangeQty={ changeQty } /> */}

            {/* <a href="#" className={ `btn btn-dark add-cart shopping-cart mr-2 ${ attrs.sizes.length > 0 || attrs.colors.length > 0 ? 'disabled' : '' }` } title="Add To Cart" onClick={ onAddCartClick }>Add to Cart</a> */}
            {/* <button
              className="btn btn-dark add-cart mr-2"
              title="Add To Cart"
              onClick={() => onAddCartClick(productData,1)}
            >
              Add to Cart
            </button> */}
                       {checkStock?<a className={ `btn btn-dark add-cart shopping-cart mr-2 ${ attrs?.sizes?.length > 0 || attrs?.colors?.length > 0 ? 'disabled' : '' }` } title="Add To Cart" onClick={()=>onAddCartClick(product,qty)}  style={{ color: 'white' }}>Add to Cart </a>:<a className={ `btn btn-dark add-cart shopping-cart mr-2 disabled` } title="Out Of Stock"   style={{ color: 'white' }}>Out Of Stock </a>} 

            <a href="#" className={`btn-icon-wish add-wishlist ${isInWishlist() ? 'added-wishlist' : ''}`} onClick={onWishlistClick} title={`${isInWishlist() ? 'Go to Wishlist' : 'Add to Wishlist'}`}><i
              className="icon-wishlist-2"></i><span>{isInWishlist() ? 'Go to Wishlist' : 'Add to Wishlist'}</span></a>
          </div>

          <hr className="divider mb-0 mt-0" />

          <div className="product-single-share mb-3">
            {/* <label className="sr-only">Share:</label>

                            <div className="social-icons mr-2">
                                <ALink href="#" className="social-icon social-facebook icon-facebook"
                                    title="Facebook"></ALink>
                                <ALink href="#" className="social-icon social-twitter icon-twitter"
                                    title="Twitter"></ALink>
                                <ALink href="#" className="social-icon social-linkedin fab fa-linkedin-in"
                                    title="Linkedin"></ALink>
                                <ALink href="#" className="social-icon social-mail icon-mail-alt"
                                    title="Mail"></ALink>
                            </div> */}
            {/* <a href="#" className={ `btn-icon-wish add-wishlist ${ isInWishlist() ? 'added-wishlist' : '' }` } onClick={ onWishlistClick } title={ `${ isInWishlist() ? 'Go to Wishlist' : 'Add to Wishlist' }` }><i
                            className="icon-wishlist-2"></i><span>{ isInWishlist() ? 'Go to Wishlist' : 'Add to Wishlist' }</span></a> */}

          </div>
        </div>
      }
    </>
  );
};

// const mapStateToProps = (state) => {
//     return {
//         wishlist: state.wishlist.list ? state.wishlist.list : []
//     }
// }

export default ProductDetailOne;
